import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyRunITBackgroundVideoImg = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "activity/runITBackgroundVideoImg.jpg"}) {
          id
          childImageSharp {
            fixed (width: 1440, height: 480, quality:50) {
              ...GatsbyImageSharpFixed
            }
          }
      }
    }
  `)
  return <Img fluid={data.file.childImageSharp.fixed} alt=""/>
}
