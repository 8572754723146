import React from "react"
import styled from "astroturf/index"

export const Point = ({ onClick, active }) => {
  const pointData = React.useMemo(() => {
    return [0, 1, 2, 3, 4].map(item => {
      return active === item ? <ActivePoint /> : <PointItem onClick={() => onClick(item)} />
    })
  }, [onClick, active])

  return <PointContainer>{pointData}</PointContainer>
}

const PointContainer = styled.div`
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(50%);
  background: transparent;
`

const PointItem = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s background ease-in-out;

  &:hover {
    background: #ffffff;
  }
`

const ActivePoint = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid #ffffff;
`
