import React from "react"
import "../common.scss"
import { SEO } from "../components/seo"
import { Footer } from "../components/footer/Footer"
import { ACTIVITY_PAGE_DESCRIPTION, ACTIVITY_PAGE_TITLE } from "../constants/seo"
import { WriteToUs } from "../components/writeToUs/WriteToUs"
import { DDayBlock } from "../components/pageActivity/DDayBlock"
import { ZaBegBlock } from "../components/pageActivity/ZaBegBlock"
import { DevDayBlock } from "../components/pageActivity/DevDayBlock"
import { MiniFootballBlock } from "../components/pageActivity/MiniFootballBlock"
import ReactPageScroller from "react-page-scroller"
import { ActivityFooter } from "../components/pageActivity/ActivityFooter"
import { useWindowSize } from "../hooks/useWindowSize"
import { Point } from "../components/pageActivity/Point"
import { Header } from "../components/newHeader/Header"

class MySection extends React.Component {
  render() {
    return <div className="section">{this.props.children}</div>
  }
}

const Activity = () => {
  const { width } = useWindowSize()
  const [activePage, setActivePage] = React.useState(0)

  const goTo = value => {
    setActivePage(value)
  }

  return (
    <>
      <SEO title={ACTIVITY_PAGE_TITLE} description={ACTIVITY_PAGE_DESCRIPTION} />
      {/* <HeaderOld activeLink={"Activity"} stickyHeader={true} goTo={goTo}/> */}
      <Header activeLink={"Activity"} stickyHeader={true} goTo={goTo} />

      {width > 1199 && (
        <>
          <ReactPageScroller pageOnChange={goTo} animationTimer={500} customPageNumber={activePage}>
            <MySection>
              <DDayBlock />
            </MySection>
            <MySection>
              <ZaBegBlock />
            </MySection>
            <MySection>
              <DevDayBlock />
            </MySection>
            <MySection>
              <MiniFootballBlock goTo={goTo} />
            </MySection>
            <MySection>
              <ActivityFooter />
              <Footer />
            </MySection>
          </ReactPageScroller>
          <Point onClick={goTo} active={activePage} />
        </>
      )}
      {width <= 1199 && (
        <>
          <DDayBlock />
          <ZaBegBlock />
          <DevDayBlock />
          <MiniFootballBlock />
          <WriteToUs />
          <Footer />
        </>
      )}
    </>
  )
}

export default Activity
