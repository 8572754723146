import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const GatsbyFootballBackgroundVideoImg = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "activity/footballBackgroundVideoImg.jpg" }) {
        id
        childImageSharp {
          fixed(width: 1440, height: 480, quality:70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return <Img fluid={data.file.childImageSharp.fixed} alt="Мини-футбол DEX" style={{ width: "100%", height: "100%" }} />
}
