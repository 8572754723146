import styled from "astroturf/index"
import React from "react"
import Slider from "react-slick"

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1.05,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
}

export const ImageBlock = ({ children }) => {
  return (
    <>
      <Container>
        <div>
          {window.innerHeight > 830 && children && children[0]}
          {children && children[1]}
          {children && children[2]}
        </div>
      </Container>
      <ContainerSlider>
        <Slider {...settings}>{children}</Slider>
      </ContainerSlider>
    </>
  )
}

const Container = styled.div`
  position: absolute;
  width: 380px;
  bottom: 50%;
  transform: translateY(50%);
  right: 10%;
  z-index: 2;

  margin-bottom: -85px;

  @media screen and (max-width: 2000px) {
    width: 310px;
    margin-bottom: -65px;
  }

  @media (max-width: 1199px) {
    display: none;
  }

  img {
    padding: 0 0 60px 0;

    @media screen and (max-width: 2000px) {
      padding: 0 0 40px 0;
    }
  }
`

const ContainerSlider = styled.div`
  display: none;
  @media (max-width: 1199px) {
    display: block;
  }

  img {
    @media (max-width: 1199px) {
      width: 150px;
      padding: 0 15px;
    }
  }
`
