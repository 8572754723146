import styled from "astroturf"
import React from "react"
import { WidthContainer } from "../../ui/WidthContainer"
import { WriteToUsForm } from "../writeToUs/WriteToUsForm"
import { useWindowSize } from "../../hooks/useWindowSize"

export const ActivityFooter = () => {
  const { width } = useWindowSize()

  const elId = width <= 1199 ? "writeToUs" : ""

  return (
    <ColorContainer id={"writeToUs"}>
      {/*<ColorContainer id={width > 1199 ? "writeToUs" : ""}>*/}
      <WidthContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>Напишите нам</Title>
            <SubTitleDesktop>
              Мы поможем с оценкой
              <br />и планированием Вашего
              <br /> проекта проекта
            </SubTitleDesktop>
            <SubTitleMobile>
              Мы поможем с оценкой
              <br />и планированием Вашего проекта
            </SubTitleMobile>
          </TitleContainer>
          <WriteToUsForm elId={elId} />
        </ContentContainer>
      </WidthContainer>
    </ColorContainer>
  )
}

const ColorContainer = styled.div`
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #121e30;
  padding: 80px 0;
  @media screen and (max-width: 1199px) {
    padding: 30px 0 40px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
`

const TitleContainer = styled.div``

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  @media screen and (max-width: 1199px) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
`

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 30px;

  @media screen and (max-width: 1199px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`

const SubTitleDesktop = styled(SubTitle)`
  @media screen and (max-width: 1199px) {
    display: none;
  }
`

const SubTitleMobile = styled(SubTitle)`
  margin-bottom: 40px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 0;
  }
`
